import React from "react";
import { Link } from "gatsby";
import { Layout, Row, Col, BackTop, Button } from "antd";
import { Helmet } from "react-helmet";

import Header from "../../components/common/header";
import CommonFooter from "../../components/common/footer";
import TutorialSider from "../../components/guide/tutorial-sider";

import { PlusCircleOutlined } from "@ant-design/icons";

import css from "../../styles/guide.css";

const QuickStart = ({}) => (
  <div>
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="DayViewer Work Scheduler, Planner and Dashboard - get started by learning the basics of how to create tasks on DayViewer Planner and learn some of the functionality."
        />
        <title>DayViewer Planner - Quick Start Guide</title>
      </Helmet>
      <Header />
      <Layout style={{ minHeight: "100vh" }}>
        <BackTop />
        <TutorialSider />
        <Layout className="guide-body">
          <h1>Quick Start - Creating a Task</h1>
          <Row>
            <Col>
              <h3>Intro</h3>
              <p>
                In this example, we will run through a very simple task - "Make
                a Cup of Tea"
              </p>
              <p>
                The process to follow should give you a thorough enough
                grounding to get the most out of DayViewer.
              </p>
              <p>
                We recommend opening a new browser tab follow the steps along.
                It should take less than 5 minutes to complete this.
              </p>
              <p>
                NB: Steps 1-3 of this tutorial do not require a pro account -
                but for Step 4 to see the entry in table view you will need a
                pro trial or have access to a team room account.{" "}
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="tutorial-steps">
                <h3>Step 1</h3>
                <p>
                  After logging into DayViewer, Select <strong>Tasks</strong> in
                  the Side Panel Menu
                </p>
                <p>
                  If you are on a desktop you will see an in-line form along the
                  top of the main layout - this is the{" "}
                  <strong>Quick Entry</strong> form. (on mobile, the quick entry
                  will drop down when you click the{" "}
                  <Button
                    shape="circle"
                    icon={<PlusCircleOutlined />}
                    type="primary"
                  />{" "}
                  button ){" "}
                </p>
                <p>
                  Enter <strong>"Make a Cup of Tea"</strong> as the Title, as
                  shown in the image below. You can set any date and time you
                  wish, and also any duration of your choice - in the example we
                  select 15min as the duration. Click <strong>ADD</strong>{" "}
                  button to create the Task Entry.
                </p>
                <div className="tutorial-images">
                  <img
                    src="https://dayviewer.s3-us-west-2.amazonaws.com/img/home/dvnext/tutorial/Quick-Start-step-1.png"
                    alt="online task calendar"
                    title="Add a Task with quick entry"
                  />
                </div>
                <p>
                  The following images show where your new entry will be
                  available
                </p>
                <div className="tutorial-images">
                  <img
                    src="https://dayviewer.s3-us-west-2.amazonaws.com/img/home/dvnext/tutorial/Quick-Start-step-1-2.png"
                    alt="online task manager"
                    title="The task edit form"
                  />
                </div>
                <div className="tutorial-images">
                  <img
                    src="https://dayviewer.s3-us-west-2.amazonaws.com/img/home/dvnext/tutorial/Quick-Start-step-1-3.png"
                    alt="task editor"
                    title="The task edit form"
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="tutorial-steps"></div>
              <h3>Step 2</h3>
              <p>
                Great, Now let's add some more details, maybe we need a
                procedure so that in the event of this being a Team Task,
                everyone will know how to make a perfect cup of tea using the
                same method. Let's do this by going back to the Tasks Card View
                area.
              </p>
              <p>Next Click on the Edit Icon - as shown in the image below</p>
              <div className="tutorial-images">
                <img
                  src="https://dayviewer.s3-us-west-2.amazonaws.com/img/home/dvnext/tutorial/Quick-Start-step-2-1.png"
                  alt="online task edit"
                  title="Edit task"
                />
              </div>
              <p>
                The edit form will open as shown below, once opened, you will
                see that it is populated with that tasks information. We want to
                add a list to this task - so we select the <strong>List</strong>{" "}
                tab on the form.
              </p>
              <div className="tutorial-images">
                <img
                  src="https://dayviewer.s3-us-west-2.amazonaws.com/img/home/dvnext/tutorial/Quick-Start-step-2-2.png"
                  alt="online task list"
                  title="The task edit form"
                />
              </div>
              <div className="tutorial-steps">
                <h3>Step 3</h3>
                <p>
                  Next we need to fill out the form with our procedure, simply
                  copy a few list items as shown in the image below (or make up
                  your own!){" "}
                </p>
                <div className="tutorial-images">
                  <img
                    src="https://dayviewer.s3-us-west-2.amazonaws.com/img/home/dvnext/tutorial/Quick-Start-step-2-3.png"
                    alt="online task checklist"
                    title="Task checklist populated with procedure"
                  />
                </div>
                <p>
                  Once that is all done, click <strong>Save</strong> button and
                  you will return to the <strong>Task Cards View</strong>
                </p>
                <p>
                  All done, we now have a full task with a procedure all set up!
                </p>
                <div className="tutorial-images">
                  <img
                    src="https://dayviewer.s3-us-west-2.amazonaws.com/img/home/dvnext/tutorial/Quick-Start-step-2-4.png"
                    alt="online task manager"
                    title="Complete task created with procedure"
                  />
                </div>
              </div>
              <div className="tutorial-steps">
                <h3>Step 4</h3>

                <p>
                  Let's have a quick look at the calendar and some of the views
                  available
                </p>
                <p>
                  Mouse Over the button with 3 vertical dots{" "}
                  <Button shape="circle" icon="more" /> a menu of available
                  views will show up here. Select each to see how your task will
                  appear in each view.
                </p>
                <img
                  src="https://dayviewer.s3-us-west-2.amazonaws.com/img/home/dvnext/tutorial/Quick-Start-step-2-5.png"
                  alt="online task manager"
                  title="Task in Table View"
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <h3>Summary</h3>
              <p>
                It's as easy as that. Play about with this task and the various
                calendar views, don't delete it yet though!... In the next
                tutorial we will use this example to create a shopping list for
                the ingredients, attach a label, attach an image to the note
                &amp; task and set it as a recurring task! ... don't worry it's
                very quick and simple.
              </p>
              <p>
                Note that the following tutorials require DayViewer Pro
                features, you can read on to see how everything works together
                or alternatively trial Pro (Take a look{" "}
                <Link to={`/plans`}>here</Link> for more details on Pro). If you
                have access to a Team Room Account, you will be able to follow
                along within a team room - there is no need to have a Pro
                account in this case.
                <p>
                  <Link to={`/tutorial-content/tutorial-2`}>
                    Go to Next Tutorial
                  </Link>
                </p>
              </p>
            </Col>
          </Row>
        </Layout>
      </Layout>
      <CommonFooter />
    </Layout>
  </div>
);

export default QuickStart;
